import React from "react";
import { Layout, Typography, Row, Col, Divider } from 'antd';
import { Link } from 'react-router-dom'

//componentes
import { Footer } from "antd/lib/layout/layout";

//estilos
import '../../Styles/Global/footer.scss'

const { Content } = Layout
const { Title, Paragraph, Text } = Typography
/**
 *
 *
 * @export
 * @class Footer
 * @description Footer de la pagina principal
 */ 
export default function MainFooter (){


    return <Footer
        className="footer"
    >   
        <Row className="footer-wrapper">
            <Col xs={24} md={8} className="footer-about">
                <img src="/images/nexus-footer.svg" className="footer-logo"/>
                <Paragraph className="footer-descripcion">Traemos lo que necesitas de cualquier parte del mundo y nos encargamos de todos los detalles.</Paragraph>
            </Col>
            <Col xs={24} md={8} className="footer-directorio">
                <Title level={3}>Directorio</Title>
                <nav>
                    <Link to="/">Inicio</Link>
                    {/*<Link to="#">Preguntas Frecuentes</Link>*/}
                    <Link to="/terminos">Terminos y Condiciones</Link>
                    <Link to="/login">Iniciar Sesión</Link>
                </nav>
            </Col>
            <Col xs={24} md={8} className="footer-sucursales">
                <Title level={3}>Sucursales</Title>
                <div className="footer-listado">
                    <Paragraph className="sucursal">
                        <strong> Nexus Nova Aguascalientes </strong> <br />
                        {/* San Emilion 304, 20310 Viñedos San Felipe, Ags. <br /> */}
                        Jose Maria Chavez 656 Col. El Encino CP 20240 Aguascalientes México <br />
                        <a target="_blank" href="tel:+524499768841">+52 (449) 976 88 41</a>
                    </Paragraph>
                    
                    {/*<Paragraph className="sucursal">
                        <strong> Nexus Nova Guadalajara </strong> <br/>
                        Justicia 91, Colonia Esperanza, CP 44300 Guadalajara, Jalisco <br/>
                        +524492041003
                    </Paragraph>
                    <Paragraph className="sucursal">
                        <strong> Nexus Nova Guanajuato </strong> <br/>
                        Boulevard Brisas de Sarandi #304 Interior manzana 4 bodega #146 Colonia brisas del Campestre CP 37669 León Guanajuato México <br/>
                        +524792325305
                    </Paragraph>*/}
                </div>
            </Col>
            <Col span={24}><Divider/></Col>
            <Col span={24}>
                <Row justify={"space-between"} align={"middle"}>
                    <Text>@2025 Copyright I SEE YOU TECHNOLOGIES</Text>
                    <Text>Nexus Nova Todos los derechos Reservados </Text>
                </Row>
            </Col>
        </Row>
    </Footer>
    

}
