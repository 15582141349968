import React from 'react';
import { Statistic, Tag } from 'antd';


/**
 * retorna el monto de una transaccion con el color correspondiente
 * */
const RenderMontoTransaccion = (transaccion) => {

	let tipos = {
		1: "#3f8600",
		2: "#cf1322"
	}


	return <Statistic 
		value={transaccion.monto} 
		precision={2} 
		valueStyle={{ 
			color: tipos[transaccion.tipo], 
			fontSize: "14px" 
		}} 
		prefix={"$"} 
		suffix="MXN" 
	/>

}

/**
 * retorna el monto de una transaccion con el color correspondiente
 * */
const RenderMoney = (props) => {

	let {
		monto,
		precision = 2,
		valueStyle = {
			fontSize: "14px",
		},
		prefix = "$",
		suffix = "MXN",
	} = props;

	return <Statistic 
		value={monto} 
		precision={precision} 
		valueStyle={valueStyle} 
		prefix={prefix} 
		suffix={suffix} 
	/>

}

/**
 * retorna le tag del estatus de pago de una venta (ecommerce)
 * @param venta - {*}
 */
const renderTagEstatusPagoVenta = (venta) => {

    if(venta.stripe_id){
    	return <Tag color="purple">PAGADO</Tag>
    }

    return <Tag color="red">PENDIENTE</Tag>
}

/**
 * retorna le tag del estatus de pago de una venta (ecommerce)
 * @param venta - {*}
 */
const renderTagEstatusEnvioVenta = (venta) => {

    if(venta.enlace_guia_envio){
    	return <Tag color="purple">ENVIADO</Tag>
    }

    return <Tag color="red">PENDIENTE</Tag>
}


export {
	RenderMontoTransaccion,
	RenderMoney,
	renderTagEstatusPagoVenta,
	renderTagEstatusEnvioVenta
}