import React, { Component } from "react";
import { Layout, Row, Col, PageHeader, Space, Button, List,
    Tag, Pagination, Typography, Card, message, Spin, Popconfirm, Statistic
} from "antd";
import { useHistory } from 'react-router-dom'

//Componentes
import { IconDetails } from '../../Widgets/Iconos';
import { renderTagEstatusPagoVenta, renderTagEstatusEnvioVenta, RenderMoney } from "../../Utils.js"


const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')


/**
 * @class Ventas
 * @extends {Component}
 * @description Vista del listado de ventas del ecommerce
 */
class Ventas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            ventas:{
                data: [],
                page: 1,
                limit: 20,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getVentas()
    }

    /**
     * @memberof Ventas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate(prevProps) {
        if(this.props.search !== prevProps.search){
            this.getVentas({search: this.props.search, page: 1})
        }
    }


     /**
     * @memberof Ventas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    getVentas = ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
    } = this.state.ventas) => {

        this.setState({ loading: true })
        axios.get('/ventas',{
            params:{
                page,
                limit,
                search: this.props.search,
            }
        }).then(({data}) => {
            console.log("data", data);
            this.setState({ventas: data})
        }).catch(error => {
            console.log("error", error);
            message.error(error?.ressponse?.data?.message ?? "Error al obtener las ventas")
        }).finally(()=>this.setState({loading: false}))

    }

    render() {


        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Ventas
                                    </span>
                                </Col>
                            </Row>
                        }
                    />
                    <Content className="admin-content content-bg pd-1 ">
                        <List
                            loading={this.state.loading}
                            className="component-list "
                            size="large"
                            itemLayout="horizontal"
                            dataSource={this.state.ventas.data}
                            header={<Row className="header-list width-100 pl-1 pr-1 ">
                                <Col xs={24} md={2} lg={2} xl={2} xxl={2} className="center">
                                    <Text strong>FOLIO</Text>
                                </Col>
                                <Col xs={24} md={3} lg={4} xl={4} xxl={4} className="center">
                                    <Text strong>CLIENTE</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>PRODUCTOS</Text>
                                </Col>
                                <Col xs={24} md={4} lg={4} xl={4} xxl={4} className="center">
                                    <Text strong>MONTO</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>FECHA</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>ESTATUS PAGO</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>ESTATUS ENVIO</Text>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item ">
                                    <Card className="card-list">
                                        <Row className="width-100">
                                            <Col xs={24} md={2} lg={2} xl={2} xxl={2} className="center">
                                                <Text ellipsis strong>{item.folio}</Text>
                                            </Col>
                                            <Col xs={24} md={4} lg={4} xl={4} xxl={4} className="center">
                                                <Text ellipsis className="text-gray-dark">{item.cliente?.nombre_completo ?? "-"}</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                                <Text ellipsis className="text-gray-dark">{item.items?.length ?? 0} Elementos</Text>
                                            </Col>
                                            <Col xs={24} md={4} lg={4} xl={4} xxl={4} className="center">
                                                <RenderMoney monto={item.total}/>
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                                <Text ellipsis className="text-gray-dark">{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className=" center">
                                                {renderTagEstatusPagoVenta(item)}
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                                {renderTagEstatusEnvioVenta(item)}
                                            </Col>
                                            <Col xs={24} md={6} lg={2} xl={2} xxl={2} className="center">
                                                <Space direction="horizontal">
                                                    <Button
                                                        icon={<IconDetails />}
                                                        type="primary"
                                                        title="Ver Detalle"
                                                        onClick={() => this.props.history.push(`/admin/ventas-ecommerce/${item._id}`)}>
                                                    </Button>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}

                        />
                        <Row className="mt-2 pb-3">
                            <Pagination
                                current={this.state.ventas.page}
                                total={this.state.ventas.total}
                                pageSize={this.state.ventas.limit}
                                showSizeChanger={true}
                                onChange={(page, limit) => this.getVentas({ page, limit })}

                            />
                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}


export default function (props) {

    return <Ventas {...props} history={useHistory()}/>
}