import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Select } from 'antd';

const { Title } = Typography;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalDirecciones
 * @extends {Component}
 */
class ModalDirecciones  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    ModalDirecciones = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.direccion_id) {
            this.getDireccion()
        }
    }

    /**
     * @memberof ModalDirecciones
     * @method getDireccion
     * @description Obtiene una nueva direccion
     */
    getDireccion = () => {
        this.setState({ loading: true });
        axios.get('/direccion/'+this.props.direccion_id,{
        }).then(({data}) => {
            this.ModalDirecciones.current.setFieldsValue(data)
        }).catch(error => {
            console.log("error", error);
            message.error("Error al obtener la dirección")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalDirecciones
     * @method addDireccion
     * @description Añade una nueva direccion
     */
    addDireccion = (values) => {
        this.setState({ loading: true });
        axios.post('/direccion',{
            ...values
        }).then(({data}) => {
            console.log("response", data);
            message.success("Dirección creada con exito")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error.response);
            message.error(error?.response?.data?.message ?? "Error al crear la dirección")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalDirecciones
     * @method updateDireccion
     * @description Añade una nueva direccion
     */
    updateDireccion = (values) => {
        this.setState({ loading: true });
        axios.put('/direccion',{
            direccion_id: this.props.direccion_id,
            ...values
        }).then(({data}) => {
            message.success("Dirección actualizada con exito")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al actualizar la dirección")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalDirecciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if(this.props.direccion_id)
            this.updateDireccion(values)
        else
            this.addDireccion(values)
    }


    render() {
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalDirecciones}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[12,0]} className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre completo"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre y apellidos" ></Input>
                            </Form.Item>
                        </Col>
                         <Col xs={24} lg={20} >
                            <Form.Item
                                label="Estado"
                                name="estado"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el estado"
                                }]}
                            >
                               	<Select
                               		placeholder="Seleccione el estado"
                               		options={[
                               			{ label: "Aguascalientes", value: "AGU" },
									    { label: "Baja California", value: "BCN" },
									    { label: "Baja California Sur", value: "BCS" },
									    { label: "Campeche", value: "CAM" },
									    { label: "Chiapas", value: "CHP" },
									    { label: "Chihuahua", value: "CHH" },
									    { label: "Coahuila", value: "COA" },
									    { label: "Colima", value: "COL" },
									    { label: "Ciudad de México", value: "CMX" },
									    { label: "Durango", value: "DUR" },
									    { label: "Guanajuato", value: "GUA" },
									    { label: "Guerrero", value: "GRO" },
									    { label: "Hidalgo", value: "HID" },
									    { label: "Jalisco", value: "JAL" },
									    { label: "Estado de México", value: "MEX" },
									    { label: "Michoacán", value: "MIC" },
									    { label: "Morelos", value: "MOR" },
									    { label: "Nayarit", value: "NAY" },
									    { label: "Nuevo León", value: "NLE" },
									    { label: "Oaxaca", value: "OAX" },
									    { label: "Puebla", value: "PUE" },
									    { label: "Querétaro", value: "QUE" },
									    { label: "Quintana Roo", value: "ROO" },
									    { label: "San Luis Potosí", value: "SLP" },
									    { label: "Sinaloa", value: "SIN" },
									    { label: "Sonora", value: "SON" },
									    { label: "Tabasco", value: "TAB" },
									    { label: "Tamaulipas", value: "TAM" },
									    { label: "Tlaxcala", value: "TLA" },
									    { label: "Veracruz", value: "VER" },
									    { label: "Yucatán", value: "YUC" },
									    { label: "Zacatecas", value: "ZAC" },
                               		]}
                               	/>
                            </Form.Item>
                        </Col>



                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Ciudad"
                                name="ciudad"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la ciudad"
                                }]}
                            >
                                <Input placeholder="Ciudad" ></Input>

                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Calle y número"
                                name="direccion"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la calle"
                                }]}
                            >
                                <Input placeholder="Dirección" maxLength={150}></Input>

                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={10} >
                            <Form.Item
                                label="Código Postal"
                                name="codigo_postal"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingreseel código postal"
                                },{
							        pattern: /^[0-9]{4,5}$/,
							        message: "El código postal debe ser un número de 4 o 5 dígitos",
							    }]}
                            >
                                <Input placeholder="#####" ></Input>

                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={10} >
                            <Form.Item
                                label="Telefono"
                                name="telefono"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el telefono"
                                },{
							        pattern: /^[0-9]{10}$/,
							        message: "El teléfono debe contener 10 dígitos",
							    }]}
                            >
                                <Input placeholder="10 dígitos" ></Input>

                            </Form.Item>
                        </Col>

                         <Col xs={24} lg={20}>
                            <Form.Item
                                label="Detalles"
                                name="datos"
                            >
                                <Input placeholder="Información extra" maxLength={200}></Input>

                            </Form.Item>
                        </Col>

                    </Row>

                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, direccion_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={700}
    >
        <div className="center">
            <Title level={3}>{direccion_id ? "Modificar" : "Añadir"} Dirección</Title>
        </div>
        <ModalDirecciones {...props} />
    </Modal>

}