import React, { Component, useContext } from 'react';
import { Button, Popconfirm, Spin, List, Row, Col, PageHeader, Layout, message, Space, Typography, Card, Tooltip, Tag } from 'antd'

import axios from 'axios';
import { Link } from "react-router-dom";

//Componentes
import ModalCompraDetalle from "./ModalCompraDetalle"
import AvatarProductos from '../../Widgets/AvatarProductos';
import { CarritoCompra, SetCarritoCompra } from "../../../Hooks/CarritoCompra";
import { renderTagEstatusPagoVenta } from "../../Utils.js"

//estilos
import '../../../Styles/Global/productos.scss'


const { Content } = Layout;
const { Text } = Typography
const moment = require("moment");

/**
 * @export
 * @class Compras
 * @extends {Component}
 * @description Vista de Compras (ventas para el sistema) realizadas por el cliente
 */
class Compras extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            ventas:{
            	data: [],
            	page: 1,
            	limit: 4,
            	total: 0
            }
        }
    }

    componentDidMount() {
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCompras();
    }


    /**
     *
     *
     * @memberof Compras
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

    }

    /**
     *
     *
     * @param {*} [page=this.state.page] Numero de la pagina a obtener
     * @memberof Compras
     * @method getCompras
     * @description Obtiene el listado de areas
     */
    getCompras = ({
    	page = this.state.ventas.page,
    	limit = this.state.ventas.limit,
    } = this.state.ventas) => {
        let search = this.props.search;
        this.setState({loading: true})
        axios.get('/customer/ventas', {
            params: {
                page: page,
                limit:limit
            }
        }).then(response => {
        	console.log("response", response.data);
        	this.setState({
        		ventas: response.data
        	})
            

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


     /**
     * @memberof Productos
     * @description añade el producto al carrito de compra del ecommerce
     */
    addItem = (producto_id) => {

        this.setState({loading: true})
        axios.post('/customer/item/add',{
            producto_id,
        }).then(({data})=>{
            if(this.props.setCarritoCompra){
                this.props.setCarritoCompra(data.carrito)
            }
            message.success("Producto agregado con exito")
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al añadir el producto al carrito")

        }).finally(()=>this.setState({loading: false}))
    }

    render() {

        let { ventas } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Compras"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Ordenes " }}
                            dataSource={ventas.data}
                            pagination={{
                                onChange: (page, limit) => this.getCompras({ page }),
                                current: ventas.page,
                                pageSize: ventas.limit,
                                total: ventas.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className: "flex-left"
                            }}
                            renderItem={item => (
                                <List.Item className="component-list-item" style={{display: "flex", justifyContent: "center"}}>
                                    <Card
                                        style={{maxWidth: "1000px"}}
                                        title={
                                            <Row>
                                                <Col span={6}>
                                                    <Space.Compact direction="vertical">
                                                        <small> Folio </small>
                                                        <Text> {item.folio} </Text>
                                                    </Space.Compact>
                                                </Col>
                                                <Col span={6}>
                                                    <Space.Compact direction="vertical">
                                                        <small> Total </small>
                                                        <Text> $ {item.total.toMoney(true)} MXN</Text>
                                                    </Space.Compact>
                                                </Col>
                                                <Col span={6}>
                                                    <Space.Compact direction="vertical">
                                                        <small> Fecha de compra </small>
                                                        <Text> {moment(item.createdAt).format("DD MMMM YYYY")} </Text>
                                                    </Space.Compact>
                                                </Col>
                                                <Col span={6} className="flex-right">
                                                    <Text className="label-action-gray" onClick={()=>this.setState({modalVisible: true, venta_id: item._id})}>Ver detalles</Text>
                                                </Col>
                                                <Col span={24} className="mt-1">
                                                    Enlace Guía de Envío: {item.enlace_guia_envio ? <a href={item.enlace_guia_envio} target="_blank" style={{fontWeight: "bold"}}>{item.enlace_guia_envio}</a> : <Tag color="red">Pendiente</Tag>}
                                                </Col>
                                            </Row>
                                        }
                                        className="card-list"
                                    >
                                        { item.items.map(item_venta => <Row gutter={[12,12]}className="width-100" key={item_venta._id}>
                                            <Col className="">
                                                <AvatarProductos imagenes={item_venta.producto.imagenes} size={94}/>
                                            </Col>
                                            <Col flex="auto" className="">
                                                <Space.Compact direction="vertical">
                                                    <Text>{item_venta?.producto?.descripcion}</Text>
                                                    <small>{item_venta?.producto?.marca}</small>
                                                    <Text>Cantidad: {item_venta?.cantidad}</Text>
                                                </Space.Compact>
                                            </Col>
                                            <Col className="center">
                                                <Button onClick={()=>this.addItem(item_venta.producto._id)}>Volver a comprar</Button>
                                            </Col>
                                        </Row>) }
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    <ModalCompraDetalle
                        visible={this.state.modalVisible}
                        onClose={()=>this.setState({
                            modalVisible: false, venta_id: undefined
                        })}
                        venta_id={this.state.venta_id}
                    />
                    
                </Spin>
            </>
        )
    }
}

export default (props) => {

    return <Compras 
        {...props}  
        setCarritoCompra={useContext(SetCarritoCompra)}
    />
}