import React, { Component, createRef, useContext } from "react";
import { Button, Layout, Image, Typography, Row, Col, Divider, Card, Carousel, Select, Form, List, Collapse, Radio, message, Statistic, notification, Space } from 'antd';
import axios from "axios";
import { Link, useLocation } from 'react-router-dom'
import { ShoppingCartOutlined } from "@ant-design/icons";

//componentes
import Footer from "../Footer/Footer";
import { User } from "../../Hooks/Logged";
import { ProductoCard } from '../Widgets/Cards';
import { CarritoCompra, SetCarritoCompra } from "../../Hooks/CarritoCompra";

//estilos
import '../../Styles/Global/footer.scss'
import '../../Styles/Global/productos.scss'
import 'iseeyoutech-widget-web/dist/style.css'

const { Content } = Layout
const { Title, Paragraph, Text } = Typography
/**
 *
 *
 * @export
 * @class ProductoDetalle
 * @extends {Component}
 * @description Pagina donde se muestra a detalle el producto
 */ 
class ProductoDetalle extends Component {

    constructor(props){
        super(props)
        this.state = {
           loading: false,
           loading_item: false,
           producto: {
            imagenes: []
           },
           imagen: {}
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if(this.props.match.params.producto_id){
        	this.getProducto()
        }
    }

    componentDidUpdate(prevProps){
       
    }

    /**
     * @memberof ProductoDetalle
     * @description Obtenemos el producto
     */
    getProducto = () => {
        this.setState({ loading: true })
        axios.get("/public/producto", {
            params: { 
            	producto_id: this.props.match.params.producto_id
           	}
        })
        .then(async ({ data }) => {
        	console.log("data", data);
           	this.setState({ 
           		producto: data,
           		imagen: data.imagenes[0] ?? null
           	})
        })
        .catch(res => {
            message.error(res?.response?.data?.message ?? 'No se pudo cargar el producto')
            console.log("no obtenida", res.response.data);
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ProductoDetalle
     * @description añade el producto al carrito
     */
    addItem = () => {

        if(!this.props.user){
            notification.error({
                message: "Usuario no logueado",
                description: "Es necesario iniciar sesión para añadir productos al carrito de compra.",
                placement: "topRight",
            });

            return;
        }

        console.log(this.props.carrito_compra)

        this.setState({loading_item: true})
        axios.post('/customer/item/add',{
            producto_id: this.state.producto._id,
        }).then(({data})=>{
            if(this.props.setCarritoCompra){
                this.props.setCarritoCompra(data.carrito)
            }
            message.success("Producto agregado con exito")

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al añadir el producto al carrito")

        }).finally(()=>this.setState({loading_item: false}))
    }

     /**
     * @memberof getURLImage
     * @description retorna la url de la imagen, dependiendo de que objeto se manda
     */
    getURLImage = (image) => {

    	if(image == null){
    		return "/images/nofile.png"
    	}

        if(image.filename){
            return axios.defaults.baseURL + '/upload/' + image.filename
        }

    	return image.url
    }

    render() {

        const { producto } = this.state;

        return (
            <Layout className="productos-page">
                <Content className="productos-main producto-detalle ">
                    <Row gutter={[30, 30]} style={{width: "100%"}} className="">
                        <Col xs={24}>
                            <Row gutter={[30,30]}>
                            	<Col span={12}>
                            		<Image
                            			src={this.getURLImage(this.state.imagen)}
                            			width={500}
                            			height={500}
                            		/>
                            	</Col>
                            	<Col span={12}>
                            		<Row>
                                        <Col span={24}>
                                            <Title level={2} className="producto-title">{producto.descripcion}</Title>
                                        </Col>
                                        <Col span={24}>
                                            <Text className="producto-code">Código: {producto.np_nexus}</Text>
                                        </Col>
                                        <Col span={24}>
                                            {producto.precio_unitario > 0 ? <Statistic 
                                                className="producto-price" 
                                                value={producto.precio_unitario} precision={2} prefix="$" suffix="MXN"
                                            /> : <Text className="producto-price" >Por Cotizar</Text>}
                                        </Col>
                                        <Col span={24}>
                                            <Button
                                                loading={this.state.loading_item}
                                                className="main-button" 
                                                icon={<ShoppingCartOutlined style={{ color: "inherit", fontSize: 18, lineHeight: "5px" }} />}
                                                onClick={()=>this.addItem()}
                                                disabled={!producto.ecommerce}
                                            >{ producto.ecommerce ? "Añadir al Carrito" : "Solo para cotizar"}</Button>
                                        </Col>
                                    </Row>
                            	</Col>
                                <Col span={24}>
                                    <Space wrap={true}>
                                    {
                                        producto.imagenes?.map(imagen => <Image className="hover" src={this.getURLImage(imagen)} width={85} height={85} preview={false} onClick={()=>this.setState({imagen})}/>)
                                    }
                                    </Space>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <List
                                        header={<Title level={4} className="m-0">Especificaciones Técnicas</Title>}
                                        bordered
                                        dataSource={producto.especificaciones}
                                        renderItem={(item, index) => (
                                            <List.Item style={{ backgroundColor: index % 2 === 0 ? "#e8e8e8" : "white"}}>
                                                <Text>{item}</Text> 
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                                <Col xs={24} lg={12}>
                                    <List
                                        header={<Title level={4} className="m-0">Información Adicional</Title>}
                                        bordered
                                        dataSource={producto.puntos_clave}
                                        renderItem={(item, index) => (
                                            <List.Item style={{ backgroundColor: index % 2 === 0 ? "#e8e8e8" : "white"}}>
                                                <Text>{item}</Text> 
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>

                <Footer/>
                
            </Layout>
        )
    }

}

export default (props) => {

    return <ProductoDetalle 
        {...props}
        user={useContext(User)}
        carrito_compra={useContext(CarritoCompra)}
        setCarritoCompra={useContext(SetCarritoCompra)}
    />
}