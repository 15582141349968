import React, { Component } from "react";
import { Layout, Row, Col, PageHeader, Space, Button, List, Form,
    Tag, Pagination, Typography, Card, message, Spin, Popconfirm, Statistic, Divider, Input
} from "antd";
import { useHistory } from 'react-router-dom'

//Componentes
import { RenderMoney, renderTagEstatusPagoVenta } from "../../Utils.js"
import { IconArrowBack } from '../../Widgets/Iconos';
import AvatarProductos from '../../Widgets/AvatarProductos';



const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')


/**
 * @class Ventas
 * @extends {Component}
 * @description Vista del listado de ventas del ecommerce
 */
class Ventas extends Component {

	back = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            venta: {
            	cliente_id: {},
            	items: [],
            },
            ventas:{
                data: [],
                page: 1,
                limit: 20,
                total: 0
            }
        }
    }

    formRef = React.createRef()

    componentDidMount() {
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props?.match?.params?.venta_id){
        	this.getVenta()
        }
    }

    /**
     * @memberof Ventas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate(prevProps) {

    }


    /**
     * @memberof Ventas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    getVenta = () => {
        this.setState({ loading: true })
        axios.get('/venta/'+this.props?.match?.params?.venta_id)
        .then(({data}) => {
            console.log("data", data);
            this.setState({venta: data})
            this.formRef.current.setFieldsValue({
            	...data
            })
        }).catch(error => {
            console.log("error", error);
            message.error(error?.ressponse?.data?.message ?? "Error al obtener las ventas")
        }).finally(()=>this.setState({loading: false}))

    }

    /**
     * @memberof Ventas
     * 
     * @method handleSubmit
     * @description Actualiza la guia de enlace de la venta
     */
    handleSubmit = (values) => {
    	this.setState({ loading: true })
        axios.put('/venta',{
        	venta_id: this.props?.match?.params?.venta_id,
        	...values
        })
        .then(({data}) => {
            console.log("data", data);
            message.success("Enlace actualizado")
            
        }).catch(error => {
            console.log("error", error);
            message.error(error?.ressponse?.data?.message ?? "Error al actualizar la venta")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {

    	const { venta } = this.state;

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
	                    className="site-page-header custom-page-header"
	                    title="Venta Detalle"
	                    backIcon={<IconArrowBack />}
	                    onBack={this.back}
	                />
                    <Content className="admin-content content-bg pd-1 ">
                    	<Row align="center">
                    		<Col span={20} className="">
                    			<Card>
                    				<Row gutter={[12,0]}>
                    					<Col xs={24} lg={12}>
				                            <Space.Compact direction="vertical">
				                                <Text className="text-gray"> FOLIO </Text>
				                                <Text> {venta.folio} </Text>
				                            </Space.Compact>
				                        </Col>
				                        <Col xs={24} lg={12} className="flex-right">
				                        	<Space.Compact direction="vertical"> 
				                                <Text className="text-gray"> FECHA DE COMPRA </Text>
				                                <Text> {moment(venta.fecha).format('LL h:mm a')} </Text>
				                            </Space.Compact>
				                        </Col>
				                        <Col xs={24} lg={12} className="mt-1 mb-1">
				                        	<Space.Compact direction="vertical"> 
				                                <Text className="text-gray"> DIRECCIÓN DE ENVÍO </Text>
				                                <strong> {venta.direccion?.nombre} </strong>
				                                <div>{venta?.direccion?.direccion ?? "-"}, { venta?.direccion?.ciudad ? `${venta?.direccion.ciudad}, ` : "" } { venta?.direccion?.estado ? `${venta?.direccion.estado}, ` : "" } { venta?.direccion?.codigo_postal ? `${venta?.direccion.codigo_postal}, ` : "" } México</div>
				                           		<div>Tel. {venta?.direccion?.telefono ?? "-"}</div>
				                           		<div><Text strong>Estatus Pago: {renderTagEstatusPagoVenta(venta)}</Text></div>
				                            </Space.Compact>
				                        </Col>

				                        <Col xs={24} lg={12} className="mt-1 mb-1" style={{borderLeft: "solid 1px #bbbbbb"}}>
				                        	<Space.Compact direction="vertical"> 
				                                <Text className="text-gray"> CLIENTE INFO.</Text>
				                                <strong> {venta.cliente_id?.nombre} {venta.cliente_id?.apellido}</strong>
				                                <div>{venta.cliente_id?.email}</div>
				                           		<div>Tel. {venta?.cliente_id?.telefono ?? "-"}</div>
				                            </Space.Compact>
				                        </Col>
				                        <Col xs={24}>
				                        	<Form 
				                        		onFinish={this.handleSubmit}
				                        		ref={this.formRef}
				                        		autoComplete="off"
				                        	>
										      	<Row gutter={[12]}>
										        	<Col xs={24} md={20}>
										          		<Form.Item
										          			label="Guia de envío"
										          			name="enlace_guia_envio" style={{ marginBottom: 0 }}
										          			rules={[
												              	{ required: true, message: 'Este campo es obligatorio' }
												            ]}
										          		>
										            		<Input className="width-100" placeholder="Ingrese la guia de enlace: https://www.paqueteria.com/########" />
										          		</Form.Item>
										        	</Col>
										        	<Col xs={24} md={4}>
										          		<Button 
										            		type="primary" 
										            		htmlType="submit" 
										            		block
										          		>
										            		Guardar
										          		</Button>
										        	</Col>
										      	</Row>
										    </Form>
				                        </Col>
				                        <Divider> PRODUCTOS ({venta?.items?.length}) </Divider>

				                        <Col xs={24}>
                        	
				                            <Text strong className="text-gray mb-1">  </Text>
				                            {
				                            	venta.items.map(item => <>
				                            		<Row className="width-100 mt-1" key={item._id} gutter={[8,8]}>
				                                        <Col className="">
				                                            <AvatarProductos imagenes={item.producto.imagenes} size={94}/>
				                                        </Col>
				                                        <Col flex="auto" className="">
				                                            <Space.Compact direction="vertical">
				                                                <Text>{item?.producto?.descripcion}</Text>
				                                                <small>{item?.producto?.marca}</small>
				                                                <Text>Cantidad: {item?.cantidad}</Text>
				                                                <Text>Precio: <RenderMoney monto={item?.producto?.precio_unitario}/> </Text>
				                                            </Space.Compact>
				                                        </Col>
				                                        <Col span={6} className="flex-right">
				                                            <RenderMoney monto={item.subtotal}/>
				                                        </Col>
				                                        <Divider/>
				                                    </Row>
				                            	</>)
				                            }
				                            
				                        </Col>
				                        <Col span={16} className="flex-right">
				                        	<Text strong className="text-gray">SUBTOTAL: </Text>
				                        </Col>
				                        <Col span={6} className="flex-right">
				                        	<RenderMoney monto={venta.subtotal} valueStyle={{fontWidth: "Bold", fontSize: "18px"}}/>
				                        </Col>
				                        <Col span={16} className="flex-right">
				                        	<Text strong className="text-gray">IVA (16%): </Text>
				                        </Col>
				                        <Col span={6} className="flex-right">
				                        	<RenderMoney monto={venta.impuestos} valueStyle={{fontWidth: "Bold", fontSize: "18px"}}/>
				                        </Col>
				                        <Col span={16} className="flex-right">
				                        	<Text strong className="text-gray">TOTAL: </Text>
				                        </Col>
				                        <Col span={6} className="flex-right">
				                        	<RenderMoney monto={venta.total} valueStyle={{fontWidth: "Bold", fontSize: "18px"}}/>
				                        </Col>
                    				</Row>
                    			</Card>
                    		</Col>
                    	</Row>
                    </Content>
                </Spin>
            </>
        )
    }
}


export default function (props) {

    return <Ventas {...props} history={useHistory()}/>
}