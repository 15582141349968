import React, { Component, createRef, useEffect } from "react";
import { Button, Layout, Image, Typography, Row, Col, Divider, Card, Carousel, message } from 'antd';
import { Link } from 'react-router-dom'
import { IconTargetShoting, IconFlag, IconBox } from "../Widgets/Iconos"
import { RightOutlined, LeftOutlined } from '@ant-design/icons'

import '../../Styles/Global/landing.scss'
import '../../Styles/Global/footer.scss'
import 'iseeyoutech-widget-web/dist/style.css'
import { Footer } from "antd/lib/layout/layout";
import { ProductoCard } from "../Widgets/Cards";
import axios from "axios";
import { useContext } from "react";
import Carrito, { SetCarrito } from "../../Hooks/Carrito";
import QuickQuote from "./Cotizador/QuickQuote";
import FloatingButton from "../Widgets/Floating Button/FloatingButton";
import { FaWhatsapp } from "react-icons/fa";
import WhatsappButton from "./WhatsappButton";
// import GoogleAnalytics  from "./GoogleAnalytics ";

import ReactGA from 'react-ga';

import { hotjar } from 'react-hotjar';





/*
Etiqueta Hotjar: <!-- Hotjar Tracking Code for https://nexusnova.mx/ -->
<script>
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3759135,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
</script>
*/
hotjar.initialize(3759135, 6);


/*
Etiqueta Google Tag Manager (head): <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PSRJ9GCF');</script>
<!-- End Google Tag Manager -->


Etiqueta Google Tag Manager (body): <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PSRJ9GCF"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
*/

ReactGA.initialize('7XMJXXD4QG')
ReactGA.pageview(window.location.pathname + window.location.search)





const { Content } = Layout
const { Title, Paragraph, Text } = Typography
/**
 *
 * @export
 * @class Landing
 * @extends {Component}
 * @description Pagina principal del sistema
 */
class Landing extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            productos: {
                docs: [],
                page: 1,
                limit: 12,
                sort: {
                    createdAt: -1
                }
            }
        }
    }

    componentDidMount() {
        this.getProductos()





        if (this.props.location.hash) {
            const el = document.querySelector(this.props.location.hash)
            el.scrollIntoView({ behavior: 'smooth' })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.hash !== this.props.location.hash && this.props.location.hash) {
            const el = document.querySelector(this.props.location.hash)
            el.scrollIntoView({ behavior: 'smooth' })
        }
    }

    getProductos = ({
        page,
        limit,
        sort
    } = this.state.productos) => {
        axios.get('/public/productos', {
            params: {
                page,
                limit,
                sort
            }
        }).then(({ data }) => {
            this.setState({ productos: data })
        }).catch((err) => {
            message.error("Error al obtener los productos")
        })
    }

    carouselIndustrias = createRef(null)
    carouselClientes = createRef(null)

    carouselSettings = {
        infinite: true,
        slidesToShow: 4,
        responsive: [{
            breakpoint: 1300,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 900,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 650,
            settings: {
                slidesToShow: 1
            }
        }]
    }

    getBackgrounds = () => [{
        src: 'bg-main-1.jpg'
    }, {
        src: 'bg-main-2.jpg'
    }, {
        src: 'bg-main-3.jpg'
    }, {
        src: 'bg-main-4.jpg'
    }, {
        src: 'bg-main-5.jpg'
    }]


    getClientes = () => [{
        src: 'cliente-balluf.jpg'
    }, {
        src: 'cliente-cantia.jpg'
    }, {
        src: 'cliente-ingeniate.jpg'
    }, {
        src: 'cliente-vianney.jpg'
    }, {
        src: 'cliente-tenneco.jpg'
    }, {
        src: 'cliente-tokaikogyo.jpg'
    }, {
        src: 'cliente-yanfeng.jpg'
    },{
        src: 'VOESTALPINE.jpg'
    },{
        src: 'NRB-BEARING.jpg'
    },{
        src: 'METALISTIK.jpg'
    },{
        src: 'GRUPO-SAN-JACINTO.jpg'
    },{
        src: 'DULCES-CHOMPYS.jpg'
    },{
        src: 'BOSCH.jpg'
    }]

    render() {
        return (
            <Layout className="landing-page">
                {/* <GoogleAnalytics  /> */}
                {/* <ISY 
                    email="av@iseeyoutech.com"
                    nombre="Juan Alberto Virrey"
                    proyectoId="632b31b87957e70d98f85999"
                /> */}
                <Content className="landing-main">
                    <Carousel dots={false} className="carousel" autoplay>
                        {
                            this.getBackgrounds().map((data, index) => (
                                <div className="carousel-slide">
                                    <img className="carousel-image" key={`slide-bg-${index}`} src={`/images/${data.src}`} />
                                </div>
                            ))
                        }
                    </Carousel>
                    <section className="landing-main-content">
                        <Row className="landing-main-cta" justify={"center"} gutter={[24, 24]}>
                            <Col xs={24} sm={20} md={16} lg={16} xl={17} className="main-cta-left">
                                <Title level={1} className="main-cta-title">DISMINUYE TU GASTO OPERATIVO CON NUESTRO COTIZADOR ESPECIALIZADO EN MRO</Title>
                                <Paragraph className="main-cta-subtitle">Ahorra tiempo y dinero, cotiza y en máximo 24 horas recibe propuesta personalizada</Paragraph>
                                <button className="main-cta-button" onClick={() => this.props.history.push('/marketplace')}>Cotizar Ahora</button>
                            </Col>
                            <Col xs={16} md={8} lg={8} xl={7} className="main-cta-right">
                                <div className="main-cta-stack">
                                    <img src="/images/phone.png" className="main-cta-phone" />
                                    <div className="main-cta-links">
                                        <a href="https://play.google.com/store/apps/details?id=com.nexus.cliente&hl=es_419&gl=US" target="_blank">
                                            <img src="/images/playstore.png" />
                                        </a>
                                        <a href="https://apps.apple.com/au/app/nexspot/id1608416518" target="_blank">
                                            <img src="/images/appstore.png" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Content>
                <Content>
                    <section className="landing-nuestros-clientes">
                        <Title level={2} className="nuestros-clientes-title">Nuestros Clientes</Title>
                        <Divider />
                        <Row className="nuestros-clientes-carousel" align={"middle"} gutter={[16, 16]}>
                            <Col xs={3} md={1} className="carousel-control">
                                <Button type="ghost" onClick={() => this.carouselClientes?.current?.prev()} icon={<LeftOutlined />} />
                            </Col>
                            <Col xs={18} md={22} className="carousel-wrapper">
                                <Carousel dots={false} ref={this.carouselClientes} className="carousel" {...this.carouselSettings}>
                                    {
                                        this.getClientes().map((data, index) => (
                                            <div className="carousel-slide" key={`slide-${data.name}`}>
                                                <div style={{ backgroundImage: `url(/images/${data.src})` }}></div>
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            </Col>
                            <Col xs={3} md={1} className="carousel-control">
                                <Button type="ghost" onClick={() => this.carouselClientes?.current?.next()} icon={<RightOutlined />} />
                            </Col>
                        </Row>
                    </section>
                    <section className="landing-cta-app">
                        <Row className="cta-app" gutter={[24, 24]} justify={"center"} align={"middle"}>
                            <Col className="cta-app-info" xs={24} md={18} lg={19}>
                                <Text className="cta-app-title">¡Usa nuestro simulador, descarga la app y cotiza en minutos!</Text>
                                <Text className="cta-app-subtitle">Obtén un 5% de descuento en tu primera compra</Text>
                            </Col>
                            <Col className="cta-app-links" xs={16} md={6} lg={5}>
                                <a href="https://play.google.com/store/apps/details?id=com.nexus.cliente&hl=es_419&gl=US">
                                    <img src="/images/playstore.png" />
                                </a>
                                <a href="https://apps.apple.com/au/app/nexspot/id1608416518">
                                    <img src="/images/appstore.png" />
                                </a>
                            </Col>
                        </Row>
                    </section>
                    <section className="landing-steps">
                        <Row className="steps" gutter={[24, 24]}>
                            <Col xs={24} md={8}>
                                <Text className="steps-data">+5000</Text>
                                <Text className="steps-text">Clientes Satisfechos</Text>
                            </Col>
                            <Col xs={24} md={8}>
                                <Text className="steps-data">20,000</Text>
                                <Text className="steps-text">Proyectos</Text>
                            </Col>
                            <Col xs={24} md={8}>
                                <Text className="steps-data">10</Text>
                                <Text className="steps-text">Años de Experiencia</Text>
                            </Col>
                        </Row>
                    </section>
                    <section className="landing-productos">
                        <Title level={2} className="productos-title">Descubre los productos más vendidos de nuestro catálogo</Title>
                        <Row className="productos-list" gutter={[30, 30]}>
                            {
                                this.state.productos.docs.map(producto => (
                                    <Col xs={12} md={8} lg={4}>
                                        <ProductoCard
                                            producto={producto}
                                            onAdd={() => {
                                                let is_added = this.props.carrito?.find(p => p._id === producto._id)
                                                if (is_added) {
                                                    message.info("El producto ya esta agregado")
                                                    return
                                                }

                                                const carrito = this.props.carrito
                                                carrito.push(producto)
                                                this.props.setCarrito(carrito)

                                                message.success("Producto agregado al carrito")
                                            }}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </section>
                    <section className="landing-quick-quote">
                        <QuickQuote />
                    </section>
                    <section className="landing-cta-app">
                        <Row className="cta-app" gutter={[24, 24]} justify={"center"} align={"middle"}>
                            <Col className="cta-app-info" xs={24} md={18} lg={19}>
                                <Text className="cta-app-title">¡Usa nuestro simulador, descarga la app y cotiza en minutos!</Text>
                                <Text className="cta-app-subtitle">Obtén un 5% de descuento en tu primera compra</Text>
                            </Col>
                            <Col className="cta-app-links" xs={16} md={6} lg={5}>
                                <a href="https://play.google.com/store/apps/details?id=com.nexus.cliente&hl=es_419&gl=US">
                                    <img src="/images/playstore.png" />
                                </a>
                                <a href="https://apps.apple.com/au/app/nexspot/id1608416518">
                                    <img src="/images/appstore.png" />
                                </a>
                            </Col>
                        </Row>
                    </section>
                    <section className="landing-cliente" >
                        <Row justify={"space-between"} className="cliente-header" align="middle">
                            <Col flex="none" className="cliente-title"><Title level={2}>Cliente SPOT</Title></Col>
                            <Col flex="none" className="cliente-logo"><img src="/images/cliente-spot.svg" /></Col>
                        </Row>
                        <Divider />
                        <Row gutter={[30, 30]} className="cliente-options">
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-spot-cotizacion.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Cotizaciones Inmediatas</Text>
                                <Paragraph className="cliente-descripcion">Te ofrecemos cotizaciones SPOT para tus necesidades.</Paragraph>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-spot-soporte.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Soporte Especializado</Text>
                                <Paragraph className="cliente-descripcion">Contamos con personal capacitado para atenderte en sitio.</Paragraph>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-spot-atencion.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Atención Inmediata</Text>
                                <Paragraph className="cliente-descripcion">¿Tienes una urgencia? ¡Estamos aquí para ayudarte!</Paragraph>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-spot-servicios.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Servicios Adicionales</Text>
                                <Paragraph className="cliente-descripcion">¿Necesitas un servicio en particular? ¡Cotízalo con nosotros!</Paragraph>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-spot-proyectos.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Proyectos a la Medida</Text>
                                <Paragraph className="cliente-descripcion">¡Cuéntanos sobre tu proyecto y permitenos apoyarte!</Paragraph>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-spot-seguimiento.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Seguimiento en Vivo</Text>
                                <Paragraph className="cliente-descripcion">Mantente informado con el estatus de tus solicitudes en tiempo real.</Paragraph>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-spot-vistas.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Visitas Programadas</Text>
                                <Paragraph className="cliente-descripcion">¡Agenda una visita a nuestra planta para conocer más!</Paragraph>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-spot-mantenimiento.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Mantenimiento y Reparaciones</Text>
                                <Paragraph className="cliente-descripcion">Ofrecemos reparación de equipos industriales, afilado de herramientas, etc.</Paragraph>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-spot-entregas.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Entregas Directas</Text>
                                <Paragraph className="cliente-descripcion">Llevamos tus productos directamente a tus instalaciones.</Paragraph>
                            </Col>
                        </Row>
                    </section>
                    <section className="landing-cta-app">
                        <Row className="cta-app" gutter={[24, 24]} justify={"center"} align={"middle"}>
                            <Col className="cta-app-info" xs={24} md={18} lg={19}>
                                <Text className="cta-app-title">¡Usa nuestro simulador, descarga la app y cotiza en minutos!</Text>
                                <Text className="cta-app-subtitle">Obtén un 5% de descuento en tu primera compra</Text>
                            </Col>
                            <Col className="cta-app-links" xs={16} md={6} lg={5}>
                                <a href="https://play.google.com/store/apps/details?id=com.nexus.cliente&hl=es_419&gl=US">
                                    <img src="/images/playstore.png" />
                                </a>
                                <a href="https://apps.apple.com/au/app/nexspot/id1608416518">
                                    <img src="/images/appstore.png" />
                                </a>
                            </Col>
                        </Row>
                    </section>
                    <section className="landing-pov">
                        <Row justify={"space-between"} className="cliente-header" align="middle">
                            <Col flex="none" className="cliente-title"><Title level={2}>Cliente VMI</Title></Col>
                            <Col flex="none" className="cliente-logo"><img src="/images/cliente-vmi.svg" /></Col>
                        </Row>
                        <Divider />
                        <Row gutter={[30, 30]} className="cliente-options">
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-vmi-24.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Una app diseñada para cotizar materiales en menos de 24 horas</Text>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-vmi-mexico.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Un software orgullosamente mexicano</Text>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-vmi-happy.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">En este momento más de 50 empresas en México están ahorrando tiempo usando nuestra app</Text>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-vmi-material.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Contamos con más de 50 000 productos desde lo más urgente hasta los materiales más crítico </Text>
                            </Col>
                            <Col xs={24} md={12} className="cliente-option">
                                <img src="/images/cliente-vmi-playstore.svg" className="cliente-icon" />
                                <Text className="cliente-highlight">Descarga en google play de forma gratuita y empieza a usarla</Text>
                            </Col>
                        </Row>
                    </section>
                    <section className="landing-cta-app">
                        <Row className="cta-app" gutter={[24, 24]} justify={"center"} align={"middle"}>
                            <Col className="cta-app-info" xs={24} md={18} lg={19}>
                                <Text className="cta-app-title">¡Usa nuestro simulador, descarga la app y cotiza en minutos!</Text>
                                <Text className="cta-app-subtitle">Obtén un 5% de descuento en tu primera compra</Text>
                            </Col>
                            <Col className="cta-app-links" xs={16} md={6} lg={5}>
                                <a href="https://play.google.com/store/apps/details?id=com.nexus.cliente&hl=es_419&gl=US">
                                    <img src="/images/playstore.png" />
                                </a>
                                <a href="https://apps.apple.com/au/app/nexspot/id1608416518">
                                    <img src="/images/appstore.png" />
                                </a>
                            </Col>
                        </Row>
                    </section>

                    <section id="about-us" className="landing-about">
                        <iframe className="about-video" src="https://www.youtube.com/embed/-45OT0vEmwA" title="Nexspot" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </section>
                </Content>
                <Footer
                    className="footer"
                >
                    <Row className="footer-wrapper" gutter={[32, 32]}>
                        <Col xs={24} md={8} className="footer-about">
                            <img src="/images/nexus-footer.svg" className="footer-logo" />
                            <Paragraph className="footer-descripcion">Traemos lo que necesitas de cualquier parte del mundo y nos encargamos de todos los detalles.</Paragraph>
                        </Col>
                        <Col xs={24} md={8} className="footer-directorio">
                            <Title level={3}>Directorio</Title>
                            <nav>
                                <Link to="/">Inicio</Link>
                                {/*<Link to="#">Preguntas Frecuentes</Link>*/}
                                <Link to="/terminos">Terminos y Condiciones</Link>
                                <Link to="/login">Iniciar Sesión</Link>
                            </nav>
                        </Col>
                        <Col xs={24} md={8} className="footer-sucursales">
                            <Title level={3}>Sucursales</Title>
                            <div className="footer-listado">
                                <Paragraph className="sucursal">
                                    <strong> Nexus Nova Aguascalientes </strong> <br />
                                    {/* San Emilion 304, 20310 Viñedos San Felipe, Ags. <br /> */}
                                    Jose Maria Chavez 656 Col. El Encino CP 20240 Aguascalientes México <br />
                                    <a target="_blank" href="tel:+524499768841">+52 (449) 976 88 41</a>
                                </Paragraph>
                               {/* <Paragraph className="sucursal">
                                    <strong> Nexus Nova Guadalajara </strong> <br />
                                    Justicia 91, Colonia Esperanza, CP 44300 Guadalajara, Jalisco <br />
                                    <a target="_blank" href="tel:+524492041003">+52 (449) 204 10 03</a>
                                </Paragraph>*/}
                                {/*<Paragraph className="sucursal">
                                    <strong> Nexus Nova Guanajuato </strong> <br />
                                    Boulevard Brisas de Sarandi #304 Interior manzana 4 bodega #146 Colonia brisas del Campestre CP 37669 León Guanajuato México <br />
                                    <a target="_blank" href="tel:+524792325305">+52 (479) 232 53 05</a>
                                </Paragraph>*/}
                            </div>
                        </Col>
                        <Col span={24}><Divider /></Col>
                        <Col span={24}>
                            <Row justify={"space-between"} align={"middle"}>
                                <Text>@2025 Copyright I SEE YOU TECHNOLOGIES</Text>
                                <Text>Nexus Nova Todos los derechos Reservados </Text>
                            </Row>
                        </Col>
                    </Row>
                </Footer>
                <WhatsappButton />
            </Layout>
        )
    }

}

export default (props) => {

    const setCarrito = useContext(SetCarrito)
    const carrito = useContext(Carrito)

    return <Landing {...props} carrito={carrito} setCarrito={setCarrito} />
}