import React, { Component, useContext, useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Alert, Modal } from 'antd';
import { Redirect, Link } from "react-router-dom";
import { User, SetUser } from '../../Hooks/Logged';
import '../../Styles/Global/auth.css';
import Recovery from './Recovery';


import ReCAPTCHA from "react-google-recaptcha";

const axios = require("axios").default;



/**
 *
 *
 * @class Register
 * @extends {Component}
 */
class Register extends Component {

    script = React.createRef();

    redirectLink = "/admin/dashboard";


    /**
     *Creates an instance of Register.
     * @param {*} props
     * @memberof Register
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            loading: false,
            error: {
                success: null,
                message: null
            },
            modalRegisterVisible: false,
            type: this.props.type,


            visibleRobot: false
        }
    }



    /**
     * @memberof Register
     *
     * @method handleSubmit
     * @description  Envia los datos del formulario al Servidor
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al usuario
     *
     * @returns response (array)
     **/
    handleSubmit = (values) => {
        this.setState({ loading: true })
        return axios.post('/register', {
            posicion: 'Cliente',
            tipo: 4,
            ...values
        })
            .then(({ data, headers }) => {
                const { setUser } = this.props;
                axios.defaults.headers.post["Authorization"] = headers.authorization;
                sessionStorage.setItem('token', headers.authorization);
                axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
                setUser(data.data);
                this.redirectTo('/admin/dashboard');
            })
            .catch(({ response }) => {
                console.log("response", response)

                Modal.error({
                    title: "No se ha podido registrar. Verifique que su información sea correcta y que no se haya registrado previamente.",
                    content: <>
                    ¿Ya se registró previamente <a onClick={() => this.setState({ modalRegisterVisible: true })}>Recupera tu contraseña</a>
                    </>
                })
            })
            .finally(f => {
                this.setState({ loading: false });
            })
    }

    /**
     * @memberof Register
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {
        if (this.state.log)
            return <Redirect to={this.redirectLink} />
    };


    /**
     * @memberof Register
     *
     * @method redirectTo
     * @description  Redirecciona a cierto link.
     *
     **/
    redirectTo = (to) => {
        this.redirectLink = to;
        this.setState({ log: true });
    };


    hideModal = () => {
        this.setState({
            modalRegisterVisible: false
        })
    }





    render() {
        return (
            <Spin spinning={this.state.loading}>
                {this.renderRedirect()}
                <Row gutter={[8, 0]} style={{ height: 'calc(100vh)', overflowY: 'auto', background: "url('images/bg-1.jpg')", backgroundSize: "cover" }} justify='center' align='middle' >
                    <Col span={24} flex="auto">
                        <Row justify="center" gutter={[0, 0]} align="middle" className='pd-1'>
                            <Col xs={{ span: 20, offset: 2, pull: 1 }} sm={{ span: 24, offset: 0, pull: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }} >
                                {this.state.error?.success == false ?
                                    <Alert
                                        message="¡Ha ocurrido un error!"
                                        description={this.state.error.message}
                                        type="error"
                                        closable
                                        className="pd-1 mb-1"

                                        onClose={() => this.setState({ error: { success: null, description: null } })}

                                    />
                                    : null}
                                <Card className='pd-2 mb-1 login'>
                                    <h3 className="h3-title">Registro</h3>
                                    <Form
                                        onFinish={this.handleSubmit}
                                        layout={"vertical"}
                                        requiredMark={false}
                                    >
                                        <Form.Item
                                            label={<><span style={{ color: "red" }}>* &nbsp;</span>Nombre</>}
                                            name="nombre"
                                            rules={[
                                                { required: true, message: 'Por favor ingresa tu  Nombre' }
                                            ]}>
                                            <Input placeholder="Nombre(s)" size="large" />
                                        </Form.Item>

                                        <Form.Item
                                            label={<><span style={{ color: "red" }}>* &nbsp;</span>Apellidos</>}
                                            name="apellido"
                                            rules={[
                                                { required: true, message: 'Por favor ingresa tus Apellidos' }
                                            ]}>
                                            <Input placeholder="Apellidos(s)" size="large" />
                                        </Form.Item>

                                        <Form.Item
                                            label={<><span style={{ color: "red" }}>* &nbsp;</span>Teléfono</>}
                                            name="telefono"
                                            rules={[
                                                { required: true, message: 'Por favor ingresa tus Telefono' }
                                            ]}>
                                            <Input placeholder="+526647947439" size="large" />
                                        </Form.Item>

                                        <Form.Item
                                            name="razon_social"
                                            label="Empresa  / Razón Social"
                                        >
                                            <Input type="text" placeholder="Persona Moral / Física" />
                                        </Form.Item>
                                        <Form.Item
                                            name="departamento"
                                            label="Departamento"
                                        >
                                            <Input type="text" placeholder="Área" />
                                        </Form.Item>
                                        {/*<Form.Item
                                            label={<><span style={{ color: "red" }}>* &nbsp;</span>RFC</>}
                                            name="rfc"
                                            rules={[
                                                {
                                                    pattern: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/,
                                                    message: 'El RFC no tiene el formato correcto.'
                                                },
                                            ]}
                                        >
                                            <Input type="text" placeholder="XAXX01011000XX0." />
                                        </Form.Item>*/}
                                        {/*<Form.Item
                                            name="direccion"
                                            label={<><span style={{ color: "red" }}>* &nbsp;</span>Dirección</>}
                                            rules={[
                                                { required: true, message: 'Por favor ingresa tu  dirección' }
                                            ]}>
                                            <Input size="large" />
                                        </Form.Item>*/}
                                        <Form.Item
                                            name="email"
                                            // label="Email"
                                            label={<><span style={{ color: "red" }}>* &nbsp;</span>Email</>}
                                            rules={[
                                                { required: true, message: 'Por favor ingresa tu  correo' }
                                            ]}>
                                            <Input placeholder="ejemplo@ejemplo.com" size="large" />
                                        </Form.Item>


                                        <Form.Item
                                            label={<><span style={{ color: "red" }}>* &nbsp;</span>Contraseña</>}
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Por favor ingresa tu  contraseña'
                                                },
                                                {
                                                    min: 8,
                                                    message: 'La contraseña debe de tener mínimo 8 caracteres'
                                                },
                                                {
                                                    pattern: new RegExp("^(?=.*[0-9])(?=.*[a-z]|[A-Z]).+$"),
                                                    message: 'Debe tener números y letras en mayusculas y minusculas'
                                                },
                                            ]}>
                                            <Input.Password />
                                        </Form.Item>

                                        <Form.Item
                                            name="password_confirmation"
                                            label={<><span style={{ color: "red" }}>* &nbsp;</span>Confirmar Contraseña</>}
                                            dependencies={['password']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Debe de confirmar su contraseña',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Las contraseñas no coinciden'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                        <Form.Item
                                            className='w-100'
                                            name="captcha"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Indique el CAPTCHA',
                                                },
                                            ]}
                                        >
                                            <ReCAPTCHA
                                                size="normal"
                                                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" block size="large" className="button-Register" >Crear Cuenta </Button>
                                        </Form.Item>
                                        <Divider />
                                    </Form>

                                </Card>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Recovery
                    visible={this.state.modalRegisterVisible}
                    hideModal={this.hideModal}
                />
            </Spin>
        )
    }
}



export default function (props) {

    let user = useContext(User)
    const setUser = useContext(SetUser)

    return <Register {...props} user={user} setUser={setUser} />
}