import React, { Component, useContext } from 'react';
import { Form, Input, Button, Row, Col, message, PageHeader, Tabs, Spin, List, Typography, Space, Card, Modal } from 'antd';
import axios from "axios";

import ModalDireccion from "../Carrito/ModalDireccion"
import { SetUser } from '../../../Hooks/Logged';

const { TabPane } = Tabs;
const { Title, Text } = Typography;


class AccountForm extends Component {


	constructor(props){
		super(props)
        this.state = {
            loading: true,
            direcciones: [],
            modal_direccion: false,
            direccion_id: undefined
        }
	}

	componentDidMount(){
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getCuenta()
	}

	formRef = React.createRef();

	// Método para manejar el envío del formulario
	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.put('/customer/cuenta',{...values})
		.then(({data})=>{
			this.props.setUser(data)
			message.success('Información actualizada con éxito');
		}).catch(error => {
			message.error(error?.response?.data?.message ?? "Error al obtner la Información")
		}).finally(()=>{
			this.setState({loading: false})
		})
		console.log('Información enviada:', values);
		
	};

	// Método para manejar errores del formulario
	handleFailedSubmit = (errorInfo) => {
		console.log('Error al enviar:', errorInfo);
		message.error('Por favor, revisa los campos del formulario.');
	};

	/**
     * @memberof Cuenta
     * @method getCuenta
     * @description Obtiene la informacion del usuario logeado
     */
	getCuenta = () => {
		this.setState({ loading: true })
		axios.get('/customer/cuenta')
		.then(({data})=>{
			console.log("data", data);
			this.formRef.current.setFieldsValue({
				nombre: data.usuario.nombre,
				apellido: data.usuario.apellido,
				email: data.usuario.email,
				rfc: data.cliente.rfc,
				telefono: data.cliente.telefono,
				departamento: data.cliente.departamento,
				razon_social: data.cliente.razon_social,
			})

			this.setState({direcciones: data.direcciones ?? []})

		}).catch(error => {
			message.error(error?.response?.data?.message ?? "Error al obtner la Información")
		}).finally(()=>{
			this.setState({loading: false})
		})
	} 



    /**
     * @memberof Cuenta
     * @method deleteDireccion
     * @description Elimina una direccion
     */
    deleteDireccion = (direccion_id) => {

        Modal.confirm({
            title: "¿Desea eliminar la dirección?",
            content: "Esta acción eliminará permanentemente la dirección seleccionada.",
            onOk: () => {
                this.setState({ loading: true });
                axios.delete('/direccion',{
                    params:{
                        direccion_id: direccion_id,
                    }
                }).then(({data}) => {
                    message.success("Dirección eliminada con exito")
                    this.getCuenta()
                }).catch(error => {
                    console.log("error", error);
                    message.error("Error al eliminar la dirección")
                }).finally(()=>this.setState({loading: false}))
            }
        })

    }

	render() {
		return (
			<>
				<PageHeader
					className="site-page-header custom-page-header"
					title="Mi Cuenta"
				/>
				<Row justify="center" style={{ marginTop: '50px', marginBottom: "100px" }}>
					<Col xs={22} md={16} lg={12}>
						<Tabs defaultActiveKey="1">
							<TabPane tab="Información Básica" key="1">
								<h2 style={{ textAlign: 'center' }}>Actualizar Información Personal</h2>
								<Spin spinning={this.state.loading}>
									<Form
										ref={this.formRef}
										layout="vertical"
										onFinish={this.handleSubmit}
										onFinishFailed={this.handleFailedSubmit}
									>
										<Row gutter={[12,0]}>
											<Col span={12}> 
												<Form.Item
													label="Nombre"
													name="nombre"
													rules={[{ required: true, message: 'Por favor ingresa tu nombre completo.' }]}
												>
													<Input placeholder="Ingresa tu nombre" />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													label="Apellidos"
													name="apellido"
													rules={[{ required: true, message: 'Por favor ingrese sus apelidos.' }]}
												>
													<Input placeholder="Ingresa tus apellidos" />
												</Form.Item>
											</Col>
										</Row>

										<Form.Item
											label="Correo Electrónico"
											name="email"
											rules={[
												{ required: true, message: 'Por favor ingresa tu correo electrónico.' },
												{ type: 'email', message: 'Por favor ingresa un correo válido.' },
											]}
										>
											<Input placeholder="Ingresa tu correo electrónico" />
										</Form.Item>

										<Form.Item
										  	label="Número de Teléfono"
										  	name="telefono"
										  	rules={[
										    	{ required: true, message: 'Por favor ingresa tu número de teléfono.' },
										    	{
										      		pattern: /^\d{10}$/,
										      		message: 'El número de teléfono debe tener exactamente 10 dígitos.',
										    	},
										  	]}
										>
										  <Input placeholder="Ingresa tu número de teléfono" />
										</Form.Item>


										<Form.Item
											label="RFC"
											name="rfc"
											rules={[
												{
													pattern: /^[A-ZÑ&]{3,4}\d{6}[A-Z\d]{2}[A\d]$/,
													message: 'Por favor ingresa un RFC válido.',
												},
											]}
										>
											<Input placeholder="Ingresa tu RFC" />
										</Form.Item>


										<Form.Item
											label="Departamento"
											name="departamento"
										>
											<Input placeholder="Ingresa el departamento" maxLength={50}/>
										</Form.Item>

										<Form.Item
											label="Razón Social"
											name="razon_social"
										>
											<Input placeholder="Ingresa la Razón Social" maxLength={50}/>
										</Form.Item>

										<Form.Item>
											<Button type="primary" htmlType="submit" block>
												Guardar Cambios
											</Button>
										</Form.Item>
									</Form>
								</Spin>
							</TabPane>
							<TabPane tab="Direcciones" key="2">
								<h2 style={{ textAlign: 'center' }}>Gestionar Direcciones</h2>
								<List
	                           		dataSource={this.state.direcciones}
	                                footer={<div>
	                                    <Text 
	                                        className="label-action-gray"
	                                        onClick={()=>this.setState({modal_direccion: true})}
	                                    >Agregar Dirección</Text>
	                                </div>}
	                           		renderItem={(item, index) => (
	                           			<Card size="small" style={{marginBottom: "4px"}} key={index}>
	                           				<Row>
	                           					<Col span={22}>
	                           						<Title level={5} className="m-0">{item?.nombre ?? "-"}</Title>
	                           						<div>{item?.direccion ?? "-"}, { item?.ciudad ? `${item.ciudad}, ` : "" } { item?.estado ? `${item.estado}, ` : "" } { item?.codigo_postal ? `${item.codigo_postal}, ` : "" } México</div>
	                           						<div>Tel. {item.telefono ?? "-"}</div>
	                                                <Space>
	                                                    <Text 
	                                                        className="label-action-gray"
	                                                        onClick={()=>this.setState({modal_direccion: true, direccion_id: item._id})}
	                                                    >Modificar</Text>
	                                                    <Text 
	                                                        className="label-action-gray"
	                                                        onClick={()=>this.deleteDireccion(item._id)}
	                                                    >Eliminar</Text>

	                                                </Space>
	                           					</Col>
	                           				</Row>
	                           			</Card>
	                           		)}
	                           	/>
							</TabPane>
						</Tabs>
					</Col>
				</Row>
				<ModalDireccion
                    visible={this.state.modal_direccion}
                    onClose={(flag)=>{
                        this.setState({modal_direccion: false, direccion_id: undefined})
                        if(flag){
                            this.getCuenta()
                        }
                    }}
                    direccion_id={this.state.direccion_id}
                />
			</>
		);
	}
}

export default function (props) {

    const setUser = useContext(SetUser)

    return <AccountForm 
        {...props}
        setUser={setUser}
    />
}