import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Statistic, Tag, message, Spin, Divider, Space } from 'antd';

//componentes
import { PaperClipOutlined } from "@ant-design/icons";
import AvatarProductos from '../../Widgets/AvatarProductos';
import { RenderMoney } from "../../Utils.js"

const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalCompraDetalle
 * @extends {Component}
 */
class ModalCompraDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            venta: {
            	direccion: {},
            	items: [],
            }
        }

    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if(this.props.venta_id){
        	this.getVenta()
        }
    }


    /**
    * @memberof ModalTran
    * @method getVenta
    * @description Obtiene la informacion de la venta para el modal de detalle.
    */
    getVenta = (id) => {
        this.setState({ loading: true })
        axios.get('/customer/venta/' + this.props.venta_id)
        .then(({ data }) => {
        	console.log("data", data);
            this.setState({ venta: data });
        })
        .catch(error => {
            console.log('error', error.response)
            message.error("Error al obtener la información de la compra")
        })
        .finally(() => {
            this.setState({ loading: false })
        })

    }

    render() {
        const { venta } = this.state;

        return (
            <Form layout="vertical"   >
                <Title level={3} className="text-center">Detalle Compra </Title>
                <Spin spinning={false}>
                    <Row justify="center" gutter={[24,8]} className="mb-3">
                        <Col xs={24} lg={11}>
                            <Space.Compact direction="vertical">
                                <Text className="text-gray"> FOLIO </Text>
                                <Text> {venta.folio} </Text>
                            </Space.Compact>
                        </Col>
                        <Col xs={24} lg={11} className="flex-right">
                        	<Space.Compact direction="vertical"> 
                                <Text className="text-gray"> FECHA DE COMPRA </Text>
                                <Text> {moment(venta.fecha).format('LL')} </Text>
                            </Space.Compact>
                        </Col>
                        <Col xs={22} className="mt-1 mb-1">
                        	<Space.Compact direction="vertical"> 
                                <Text className="text-gray"> DIRECCIÓN DE ENVÍO </Text>
                                <strong> {venta.direccion?.nombre} </strong>
                                <div>{venta?.direccion?.direccion ?? "-"}, { venta?.direccion?.ciudad ? `${venta?.direccion.ciudad}, ` : "" } { venta?.direccion?.estado ? `${venta?.direccion.estado}, ` : "" } { venta?.direccion?.codigo_postal ? `${venta?.direccion.codigo_postal}, ` : "" } México</div>
                           		<div>Tel. {venta?.direccion?.telefono ?? "-"}</div>
                            </Space.Compact>
                        </Col>
                        <Col xs={22}>
                        	
                            <Text strong className="text-gray mb-1"> PRODUCTOS ({venta?.items?.length}) </Text>
                            {
                            	venta.items.map(item => <>
                            		<Row className="width-100 mt-1" key={item._id} gutter={[8,8]}>
                                        <Col className="">
                                            <AvatarProductos imagenes={item.producto.imagenes} size={94}/>
                                        </Col>
                                        <Col span={14} flex="auto" className="">
                                            <Space.Compact direction="vertical">
                                                <Text>{item?.producto?.descripcion}</Text>
                                                <small>{item?.producto?.marca}</small>
                                                <Text>Cantidad: {item?.cantidad}</Text>
                                                <RenderMoney monto={item?.producto?.precio_unitario}/>
                                            </Space.Compact>
                                        </Col>
                                        <Col span={6} className="flex-right">
                                            <RenderMoney monto={item.subtotal}/>
                                        </Col>
                                        <Divider/>
                                    </Row>
                            	</>)
                            }
                            
                        </Col>
                        <Col span={16} className="flex-right">
                            <Text strong className="text-gray">SUBTOTAL: </Text>
                        </Col>
                        <Col span={6} className="flex-right">
                            <RenderMoney monto={venta.subtotal} valueStyle={{fontWidth: "Bold", fontSize: "18px"}}/>
                        </Col>
                        <Col span={16} className="flex-right">
                            <Text strong className="text-gray">IVA (16%): </Text>
                        </Col>
                        <Col span={6} className="flex-right">
                            <RenderMoney monto={venta.impuestos} valueStyle={{fontWidth: "Bold", fontSize: "18px"}}/>
                        </Col>
                        <Col span={16} className="flex-right">
                        	<Text strong className="text-gray">TOTAL: </Text>
                        </Col>
                        <Col span={6} className="flex-right">
                        	<RenderMoney monto={venta.total} valueStyle={{fontWidth: "Bold", fontSize: "18px"}}/>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={800}
    >
        <ModalCompraDetalle {...props} />
    </Modal>

}